import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Profil from './pages/Profil';
import Home from './pages/Home';
import ContactPage from './pages/ContactPage';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ContentPage from './pages/ContentPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/photos/:code",
    element: <Profil/>,
  },
  {
    path: "/contact",
    element: <ContactPage/>,
  },
  {
    path: "/conditions-generales-utilisation",
    element: <ContentPage/>
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
