import React from 'react';
import '../styles/Footer.scss';
import FacebookLogo from '../assets/Facebook2.png'
import InstagramLogo from '../assets/Instagram 1.png'
import TwitterLogo from '../assets/Twitter.svg'

function Footer(props) {
    return (
       <div className='footer'>
        <div className='footer-container'>
            <div className='footer-content'>
                <div className='contact-footer'>
                    <h2 >Triportrait</h2>
                    <div><a href='tel:+33615402379'>+33615402379</a></div>
                    <div><a href="mailto:triportrait@gmail.com">triportrait@gmail.com</a></div>
                </div>
                <div className='links-footer'>
                    <h2>Liens</h2>
                    <div className='links-footer-content'>
                        <div className='section-links'>
                            <div><a href="https://triportrait.com/conditions-generales-de-vente">CGV</a></div>
                            <div><a href="https://pellicule.triportrait.com/conditions-generales-utilisation">CGU</a></div>
                            <div><a href="https://triportrait.com/mentions-legales">Mentions légales</a></div>
                            <div><a href="https://triportrait.com/foire-aux-questions">FAQ</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-footer'>
                <div className='footer-logo'>
                    <a href="https://www.instagram.com/triportrait" target="_blank"><img src={InstagramLogo} className="svg-logo" alt="Linkedin Logo" /></a>
                    <a href="https://www.facebook.com/annecy.photographe" target="_blank"><img src={FacebookLogo} className="svg-logo" alt="Facebook Logo" /></a>
                </div>
                <span id="right">© {new Date().getFullYear()} Triportrait. Tous droits réservés</span>
            </div>
        </div>
        </div>
    );
}

export default Footer;