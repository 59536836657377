import React from 'react';
import triportrait from '../assets/triportrait-photobooth-test.png';
import "../styles/Header.scss";
import {RxHamburgerMenu, RxCross1} from 'react-icons/rx';
import hamburger from '../assets/hamburger.png';
import { useState } from 'react';
import { Link } from "react-router-dom";
import "../styles/TextContent.scss";
import axios from "axios";

function TextContent(props) {
    
    const [cgu, setCgu] = useState("");

    var parse = require('html-react-parser');


    useState(() => {
        axios.get("https://pellicule.triportrait.com/api/cgu").then((e) => {
            console.log(e.data);
            setCgu(e.data)
        });
    }, [])

    return (
        <div class="text-content-container">
            {parse(cgu)}
        </div>
    );
}

export default TextContent;