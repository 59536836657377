import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import MainProfil from '../components/MainProfil';
import Footer from '../components/Footer';

Profil.propTypes = {
    
};

function Profil(props) {
    return (
        <>
            <Header></Header>
            <MainProfil></MainProfil>
            <Footer></Footer>
        </>
    );
}

export default Profil;