import React, { useEffect } from 'react';
import '../styles/MainProfil.scss';
import '../styles/Swiper.scss';
import { useState } from 'react';
import profil_picture from '../assets/people.jpeg';
import download_icon from '../assets/download.png';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";
import {RxCross1} from 'react-icons/rx';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import axios from "axios";
import { useParams } from 'react-router-dom'

MainProfil.propTypes = {};

function MainProfil(props) {
    const [infos, setInfos] = useState({
        nb_photo: 0,
        date: "",
        code: "",
        urls: []
    });

    const toto = (index) => {
        const swiper = document.querySelector('.swiper').swiper;
        // Now you can use all slider methods like
        swiper.slideTo(index);
        document.querySelector(".testt").hidden = false;
    };

    const closeSwiper = (e) => {
        const concernedElement = document.querySelector(".mySwiper");
        if (concernedElement.contains(e.target)) {
            console.log("Clicked Inside");
          } else {
            e.currentTarget.hidden = true
        }  
    };

    const { code } = useParams();


    useEffect(() => {
        axios.get("https://pellicule.triportrait.com/api/photos/" + code).then((e) => {
            e.data.date = new Date(e.data.date.date);
            setInfos(e.data);
        });
    }, []);

    const downloadZip = (e) => {
    };

    return (
        <>
            <div className="profil-container">
                <div className='profil-info-container'>
                    <div className="profil-infos">
                        <div className='profil-picture'>
                            <img src={infos.urls[0]} alt="" />
                        </div>
                        <div className="profil-info">
                            <p className="profil-title">Photos du {infos.date && infos.date.toLocaleDateString('fr-FR', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'})}</p>
                            <p className="profil-details">{infos.urls.length} photos - Code {infos.code}</p>
                        </div>
                    </div>
                    <div className="btn-download-pictures" onClick={(e) => {
                        downloadZip();
                    }}>
                        <img src={download_icon} alt="" />
                        <a href={"https://pellicule.triportrait.com/api/download/" + code + ".zip"}>Télécharger les photos</a>
                    </div>
                </div>
                <div className='line'></div>
                <div className='profil-gallery'>
                    {
                        infos.urls.map((image, index) => {
                            console.log(image);
                            return (
                                <div id={"image-" + index}><img  onClick={() => {toto(index)}} src={image} alt="" /></div>
                            );
                        })
                    }
                </div>
            </div>
            <div className='testt' onClick={closeSwiper} hidden>
                <Swiper slidesPerView={1} spaceBetween={0} keyboard={{enabled: true}} pagination={{clickable: true}}
                    navigation={true} modules={[Keyboard, Pagination, Navigation]} className="mySwiper">
                    <div className='cross-swiper' onClick={(e) => {
                                    document.querySelector(".testt").hidden = true
                    }}><RxCross1 size={24}/></div>
                    {
                        infos.urls.map((image, index) => {
                            return (
                                <SwiperSlide id={"slider-" + index}><img src={image}/></SwiperSlide>
                            );
                        })
                    }
                </Swiper>
            </div>
        </>
    );
}

export default MainProfil;