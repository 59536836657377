import React from 'react';
import triportrait from '../assets/triportrait-photobooth-test.png';
import "../styles/Header.scss";
import {RxHamburgerMenu, RxCross1} from 'react-icons/rx';
import hamburger from '../assets/hamburger.png';
import { useState } from 'react';
import { Link } from "react-router-dom";

function Header(props) {

    const [isMenu, setIsMenu] = useState(false);

    return (
        <div className="header-container">
            <div className="image-container">
                <Link to="/">
                    <img className="" src={triportrait} alt="" />
                </Link>
            </div>
            <div className="hamburger-container" onClick={() => {setIsMenu(true)}}>
                {/* <img className="" src={hamburger} alt="" /> */}
                <RxHamburgerMenu size={36} color={"var(--secondary-color)"}/>
            </div>
            <div className="navigation-container">
                <ul>
                    <a href="https://triportrait.com/" target="_blank"><li>En savoir plus</li></a>
                    <Link to="/contact">
                        <li>Contact</li>
                    </Link>
                </ul>
            </div>
            { isMenu &&
                <div className="side-menu">
                    <div className="cross" onClick={() => {setIsMenu(false)}}>
                        <RxCross1 size={24}/>
                    </div>
                    <div className="line"/>
                    <ul>
                        <a href="https://triportrait.com/" target="_blank"><li>EN SAVOIR PLUS</li></a>
                        <Link to="/contact">
                            <li>CONTACT</li>
                        </Link>
                    </ul>
                </div>
            }
        </div>
    );
}

export default Header;