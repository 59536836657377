import { wait } from '@testing-library/user-event/dist/utils';
import React, { useEffect } from 'react';


Contact.propTypes = {
    
};

function Contact(props) {
    useEffect(() => {
        var frame = document.getElementById("sn-form-crl5w");

        console.log(frame);
        console.log("frame.style.height", frame.contentWindow.document.body.scrollHeight);
        console.log("frame.style.width", frame.contentWindow.document.body.scrollWidth);
        // console.log("toto:", frame.contentWindow.document.body.scrollHeight);
        // frame.style.height = frame.contentWindow.document.body.scrollHeight + 'px'; 
        // frame.style.width  = frame.contentWindow.document.body.scrollWidth +'px';
        // console.log("frame.style.height", frame.style.height);
        // console.log("frame.style.width", frame.style.width);
    }, []);

    return (
        <>
            <iframe scrolling="no" style={{'minWidth':'100%', 'border': '0', 'marginTop': '6rem'}} id="sn-form-crl5w"
            src="https://app.studioninja.co/contactform/parser/0a800fc9-6fb1-1a6f-816f-b47e60ab04be/0a800fc9-6fb1-1a6f-816f-b47ee23304c6"
            allowFullScreen></iframe>
            <script type="text/javascript" data-iframe-id="sn-form-crl5w"
                    src="https://app.studioninja.co/client-assets/form-render/assets/scripts/iframeResizer.js"></script>
        </>
    );
}

export default Contact;