import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import "../styles/Contact.scss";

ContactPage.propTypes = {
    
};

function ContactPage(props) {
    return (
        <>
            <Header></Header>
            <Contact></Contact>
            <Footer></Footer>
        </>
    );
}

export default ContactPage;