import React, { useState, useEffect } from 'react';
import '../styles/FlashMessageComponent.scss';


function FlashMessageComponent({message, colorBackground, color, display, setIsFlash}) {

    var style = {
        "backgroundColor" : colorBackground,
        "color": color,
        "border": `1px solid ${color}`,
        "display": (display == true) ? "flex" : "none",
    }

    useEffect(() => {
        window.addEventListener("animationend", (event) => {
            var e = document.getElementById("flash");
            setTimeout(() => {
                e.style.display = "none";
                setIsFlash({...display, "display": false});
            }, 5000);
        });
    }, [])

    return (
       <div id='flash' className='flash-message animate__animated animate__bounce flash-message' style={style}>
            <p>{message}</p>
       </div>
    );
}

export default FlashMessageComponent;