import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';

Home.propTypes = {
    
};

function Home(props) {
    return (
        <>
            <Header></Header>
            <Main></Main>
            <Footer></Footer>
        </>
    );
}

export default Home;